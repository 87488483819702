import React, { useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { Typeahead } from 'react-bootstrap-typeahead';
import { HeroWithBackgroundImage } from 'components/hero/hero.component';
import PageLayout from 'components/layouts/page/page.layout';
import PageSection from 'ui-kit/page-section/page-section';
import FeatureHighlight from 'components/feature-highlight/feature-highlight.component';
import Slider from 'components/demo-slider/demo-slider.component';
import { Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import '../../../styles/demo.style.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const medOptions = [
    {
        category: 'allergy',
        name: 'Cetirizine 1 mg/1 ml oral solution - child',
        brand: 'Zyrtec®',
        brandHtml: 'Zyrtec<sup>&reg;</sup>'
    },
    {
        category: 'allergy',
        name: 'Cetirizine 10 mg - allergy tablet',
        brand: 'Zyrtec®',
        brandHtml: 'Zyrtec<sup>&reg;</sup>'
    },
    {
        category: 'allergy',
        name: 'Diphenhydramine 25 mg - allergy',
        brand: 'Benadryl®',
        brandHtml: 'Benadryl<sup>&reg;</sup>'
    },
    {
        category: 'antacids and acid reducers',
        name: 'Calcium carbonate 500 mg - antacid chewable tablet',
        brand: 'Tums®',
        brandHtml: 'Tums<sup>&reg;</sup>'
    },
    {
        category: 'antacids and acid reducers',
        name: 'Calcium carbonate 750 mg - antacid chewable tablet',
        brand: 'Tums® Extra Strength',
        brandHtml: 'Tums<sup>&reg;</sup> Extra Strength'
    },
    {
        category: 'antacids and acid reducers',
        name: 'Famotidine 10 mg tablet - acid reducer',
        brand: 'Pepcid AC®',
        brandHtml: 'Pepcid AC<sup>&reg;</sup>'
    },
    {
        category: 'antacids and acid reducers',
        name: 'Lansoprazole 15 mg capsules',
        brand: 'Prevacid®',
        brandHtml: 'Prevacid<sup>&reg;</sup>'
    },
    {
        category: 'antacids and acid reducers',
        name: 'Omeprazole tablet 20 mg',
        brand: 'Prilosec OTC®',
        brandHtml: 'Prilosec OTC<sup>&reg;</sup>'
    },
    {
        category: 'anti-diarrheal, laxatives and digestive health',
        name: 'Beanaid capsules',
        brand: 'Beano®',
        brandHtml: 'Beano<sup>&reg;</sup>'
    },
    {
        category: 'cold, cough and flu',
        name: 'Cough drop'
    },
    {
        category: 'dental and denture care',
        name: 'Fixodent denture adhesive'
    },
    {
        category: 'diabetes management',
        name: 'Glucose 4 gm chewable tablet',
        brand: 'DEX4® Glucose',
        brandHtml: 'DEX4<sup>&reg;</sup> Glucose'
    },
    {
        category: 'eye and ear care',
        name: 'Artificial tear (glycerin/hypromellose/polyethylene glycol)',
        brand: 'Visine® Tears',
        brandHtml: 'Visine<sup>&reg;</sup> Tears'
    },
    {
        category: 'health care supplies',
        name: 'Adult cloth face mask - pull strap (one size fits most)'
    },
    {
        category: 'hemorrhoidal preparations',
        name: 'Hemorrhoid (phenylephrine) suppository',
        brand: 'Preparation H® supp',
        brandHtml: 'Preparation H<sup>&reg;</sup> supp'
    },
    {
        category: 'motion sickness',
        name: 'Motion sickness tablets (dimenhydrinate)',
        brand: 'Dramamine®',
        brandHtml: 'Dramamine<sup>&reg;</sup>'
    },
    {
        category: 'pain relievers',
        name: 'Acetaminophen 160 mg chewable tablet',
        brand: "Children's Tylenol®",
        brandHtml: "Children's Tylenol<sup>&reg;</sup>"
    },
    {
        category: 'supports and braces',
        name: 'Elastic knee stabilizer size L/XL (16" - 20")'
    },
    {
        category: 'topical skin care',
        name: 'Bacitracin ointment'
    },
    {
        category: 'vitamins and minerals',
        name: 'Biotin 1000 mcg tablets'
    }
];

const getOptions = (filterValue) => {
    let filteredOptions;

    if (filterValue === 'all') {
        filteredOptions = medOptions;
    } else {
        filteredOptions = medOptions.filter((item) => {
            return item.category == filterValue;
        });
    }

    const filteredArray = [];
    filteredOptions.forEach((item) => {
        filteredArray.push(item.name);
    });

    return filteredOptions;
    // return filteredArray;
};

const OverTheCounter = () => {
    const [selection, setSelection] = useState([]);
    const [dropdown, setDropdown] = useState('ALL');
    const [meds, setMeds] = useState(getOptions('all'));
    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/blue-sky.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            birdHands: file(relativePath: { eq: "assets/images/bird-hands.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
            featureHighlightWoman: file(relativePath: { eq: "assets/images/feature-highlight-woman.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
                }
            }
        }
    `);

    const handleSelect = (value) => {
        setDropdown(value);
        setMeds(getOptions(value.toLowerCase()));
    };

    return (
        <PageLayout>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <div className="otc">
                            <div className="otc__subtitle h5">Shopping Made Easy</div>
                            <h1 className="otc__title">Over-the-Counter Products</h1>
                            <p className="otc__text h3">
                                Security Health Plan understands that certain OTC drugs and supplies can be expensive.
                                That's why we're offering you a way to save money on these items and have them
                                conveniently delivered to your home.
                            </p>
                            <div className="otc__form-title with-line">Start shopping over the counter</div>
                            <form className="otc-search">
                                <div className="otc-search__wrapper">
                                    <DropdownButton
                                        id="dropdown"
                                        title={dropdown}
                                        onSelect={handleSelect}
                                        className="otc-search__drop"
                                    >
                                        <Dropdown.Item eventKey="ALL">All</Dropdown.Item>
                                        <Dropdown.Item eventKey="Allergy">Allergy</Dropdown.Item>
                                        <Dropdown.Item eventKey="Antacids and acid reducers">
                                            Antacids and acid reducers
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Anti-diarrheal, laxatives and digestive health">
                                            Anti-diarrheal, laxatives and digestive health
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Cold, cough and flu">
                                            Cold, cough and flu
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Dental and denture care">
                                            Dental and denture care
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Diabetes management">
                                            Diabetes management
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Eye and ear care">Eye and ear care</Dropdown.Item>
                                        <Dropdown.Item eventKey="Health care supplies">
                                            Health care supplies
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Hemorrhoidal preparations">
                                            Hemorrhoidal preparations
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Motion sickness">Motion sickness</Dropdown.Item>
                                        <Dropdown.Item eventKey="Pain relievers">Pain relievers</Dropdown.Item>
                                        <Dropdown.Item eventKey="Supports and braces">
                                            Supports and braces
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Topical skin care">Topical skin care</Dropdown.Item>
                                        <Dropdown.Item eventKey="Vitamins and minerals">
                                            Vitamins and minerals
                                        </Dropdown.Item>
                                    </DropdownButton>
                                    <Typeahead
                                        onChange={setSelection}
                                        options={meds}
                                        labelKey={(option) => {
                                            const value = option.brand
                                                ? `${option.name} (${option.brand})`
                                                : `${option.name}`;
                                            return value;
                                        }}
                                        renderMenuItemChildren={(option, props, index) => {
                                            const value = option.brandHtml
                                                ? `${option.name} (${option.brandHtml})`
                                                : `${option.name}`;
                                            return (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: value
                                                    }}
                                                />
                                            );
                                        }}
                                        value={selection}
                                        minLength={1}
                                        id="typeahead"
                                        className="otc-search__input"
                                    />
                                    <button
                                        type="button"
                                        className="otc-search__btn btn"
                                        onClick={() => {
                                            navigate('/secure/over-the-counter/detail');
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </PageSection>
            <div className="recs">
                <PageSection>
                    <Row>
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <h2 className="recs__title with-line">Recommendations</h2>
                            <p className="recs__text">We’d recommend the following over-the-counter products.</p>
                            <div className="recs__slider">
                                <Slider />
                            </div>
                        </Col>
                    </Row>
                </PageSection>
            </div>
            <PageSection>
                <FeatureHighlight
                    sectionIndex={3}
                    backgroundColor="light_blue"
                    eyebrowText="Birdi Makes it Easy"
                    headerText="How it Works."
                    image={imageData.featureHighlightWoman}
                    imagePosition="left"
                    text="Each eligible plan subscriber receives a $30 quarterly OTC credit to purchase select health and wellness products through the OTC drug website. Simply search for the product you're looking for or browse by category."
                    text2="The $30 credit expires at the end of each quarter. Any unused credit will not carry over to the next quarter. If your order exceeds $30, please include payment information (debit or credit card) with your order. You may place one order per quarter."
                    textColor="inverse"
                />
            </PageSection>
            <PageSection>
                <div className="blue-links container-fluid">
                    <div className="blue-links-inner">
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <h2>Browse by Category</h2>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <ul>
                                            <li>allergy</li>
                                            <li>antacids and acid reducers</li>
                                            <li>anti-diarrheal, laxatives and digestive health</li>
                                            <li>cold, cough and flu</li>
                                            <li>dental and denture care</li>
                                            <li>diabetes management</li>
                                            <li>eye and ear care</li>
                                            <li>health care supplies</li>
                                        </ul>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <ul>
                                            <li>hemorrhoidal preparations</li>
                                            <li>motion sickness</li>
                                            <li>pain relievers</li>
                                            <li>supports and braces</li>
                                            <li>topical skin care</li>
                                            <li>vitamins and minerals</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </PageSection>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <div className="two-col">
                            <Row className="two-col-row">
                                <Col md={12} lg={6}>
                                    <h2>Product &amp; Delivery.</h2>
                                    <p className="h4 two-col-text">
                                        When placing an order, you will receive the generic equivalent of the name-brand
                                        drug or a similar product. If you choose a product that is unavailable or not in
                                        stock, a similar product may be substituted at no additional charge. Similar
                                        products are used for the same purpose but may have different ingredients,
                                        strength, flavor, dosage form or package size.
                                    </p>
                                    <p className="h4 two-col-text">
                                        Orders will be shipped to your home by the U.S. postal service at no extra cost
                                        to you. Please allow 14 business days upon receipt of order to receive shipment.
                                        To ensure the safety of your order, there may be delays in shipment due to
                                        extreme cold weather (&lt;10&deg; F) or snow accumulations.
                                    </p>
                                </Col>
                                <Col md={12} lg={6}>
                                    <h2>Order Deadlines.</h2>
                                    <p className="h4 two-col-text">
                                        If your order is received after a quarter deadline listed below, it will apply
                                        toward the next quarter. For example, if we receive your order on June 29, it
                                        will apply toward Quarter 3.
                                    </p>
                                    <p className="h4 two-col-text">
                                        <span>Quarter 1</span> Jan. 1 - March 20 (order deadline March 20)
                                    </p>
                                    <p className="h4 two-col-text">
                                        <span>Quarter 2</span> April 1 - June 20 (order deadline June 20)
                                    </p>
                                    <p className="h4 two-col-text">
                                        <span>Quarter 3</span> July 1 - Sept. 20 (order deadline Sept. 20)
                                    </p>
                                    <p className="h4 two-col-text">
                                        <span>Quarter 4</span> Oct. 1 - Dec. 20 (order deadline Dec. 20)
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </PageSection>
            <PageSection>
                <HeroWithBackgroundImage
                    sectionIndex={8}
                    backgroundImage={imageData.blueSkyBackground}
                    image={imageData.birdHands}
                    imagePosition="bottom-right"
                    eyebrowText="Without feather ado"
                    isTopOfFold={false}
                    size="large"
                    title="We’re here to help."
                    text="If you have any questions, please contact our Pharmacy Benefits Department at 1-877-216-8533 or 715-221-9208, Monday-Friday 8 a.m. to 5 p.m."
                    text2="If you are hearing- or speech-impaired, please call TTY 711."
                    useRoundedCorners={true}
                />
            </PageSection>
        </PageLayout>
    );
};

export default OverTheCounter;
